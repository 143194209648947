<template>
    <v-container>
        <!-- Header -->
        <v-row class="d-flex ma-2">
            <v-icon large left color="blue">mdi-truck</v-icon>
            <div class="text-h5 pa-4 blue--text darken-2">รายละเอียดการเบิกจ่ายเวชภัณฑ์</div>
        </v-row>
        <v-divider/>

        <!-- DETAIL -->
        <v-row class="py-4">
            <v-col cols="4" class="pa-2">
                <v-card class="d-flex flex-row align-center">
                    <v-avatar size="50" class="mx-4 elevation-1" color="blue">
                        <v-icon large dark>
                            mdi-calendar-clock
                        </v-icon>
                    </v-avatar>
                    <v-card-text class="d-flex flex-column blue--text darken-2 pl-0">
                        <p class="font-weight-bold mb-0">วันที่ทำรายการ</p>
                        <p class="font-weight-light mb-0">{{ withdrawDate }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4" class="pa-2">
                <v-card class="d-flex flex-row align-center">
                    <v-avatar size="50" class="mx-4 elevation-1" color="blue">
                        <v-icon large dark>
                            mdi-information-outline
                        </v-icon>
                    </v-avatar>
                    <v-card-text class="d-flex flex-column blue--text darken-2 pl-0">
                        <p class="font-weight-bold mb-0">ประเภทการเบิก</p>
                        <p class="font-weight-light mb-0">{{ objective }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="4" class="pa-2">
                <v-card class="d-flex flex-row align-center">
                    <v-avatar size="50" class="mx-4 elevation-1" color="blue">
                        <v-icon large dark>
                            mdi-map-marker
                        </v-icon>
                    </v-avatar>
                    <v-card-text class="d-flex flex-column blue--text darken-2 pl-0">
                        <p class="font-weight-bold mb-0">ปลายทาง</p>
                        <p class="font-weight-light mb-0">{{ destination }}</p>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <!-- HEADER -->
        <v-row class="d-flex justify-center align-center ma-2">
            <v-icon large left color="blue">mdi-file</v-icon>
            <div class="text-h5 pa-4 blue--text darken-2">รายการเวชภัณฑ์เบิกจ่าย <span class="black--text">(รวม {{sumList}} รายการ)</span></div>
            <v-spacer/>
            <v-btn tile large color="blue darken-2 white--text" @click="applyRouterLink($routes.STOCK_CARD.name)">
                <v-icon class="pr-2">mdi-printer</v-icon>
                พิมพ์สต๊อคการ์ด
            </v-btn>
        </v-row>

        
        <v-tabs grow v-model="tab">
            <v-tab class="title" active-class="blue darken-2 white--text rounded-top">เวชภัณฑ์ ({{itemDrugList.length}} รายการ)</v-tab>
            <v-tab class="title" active-class="blue darken-2 white--text rounded-top">เวชภัณฑ์ที่มิใช่ยา ({{itemNonDrugList.length}} รายการ)</v-tab>
            <v-tab class="title" active-class="blue darken-2 white--text rounded-top">สมุนไพร ({{itemHerbList.length}} รายการ)</v-tab>
            <v-tab class="title" active-class="blue darken-2 white--text rounded-top">อื่นๆ ({{itemOtherList.length}} รายการ)</v-tab>
        </v-tabs>
        <v-divider class="blue darken-2"/>

        <v-tabs-items v-model="tab">
            <!-- Med -->
            <v-tab-item>
                <v-data-table :items-per-page="10" :items="itemDrugList" :headers="itemDrugHeader" group-by="genericName" :footer-props="{'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'}">

                    <template v-slot:item.tpuName="{item}">
                        {{item.tpuName}} - (ล็อต {{item.lotNo}})
                    </template>

                    <template v-slot:item.quantity="{item}">
                        {{item.quantity}} {{item.unit}}
                    </template>

                    <!-- <template v-slot:item.currentQuantity="{item}">
                        {{item.currentQuantity}} {{item.unit}}
                    </template> -->

                    <template v-slot:group.header="{group, items, isOpen, toggle}">
                        <th colspan="1" class="text-body-1">
                            <v-icon @click="toggle" :ref="group" :is-expanded="isOpen">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            {{ items[0].genericName }}
                        </th>
                        <th colspan="1" class="text-body-1">
                            {{items[0].expired}}
                        </th>
                        <th colspan="1" class="text-body-1">
                                {{sumQuantity(items)}} {{items[0].unit}}
                        </th>
                        <!-- <th colspan="1" class="text-body-1">
                                {{items[0].sumCurrentQuantity}} {{items[0].unit}}
                        </th> -->
                    </template>
                </v-data-table>
            </v-tab-item>
            <!-- Non Med -->
            <v-tab-item>
                <v-data-table :items-per-page="10" :items="itemNonDrugList" :headers="itemNonDrugHeader" group-by="genericName" :footer-props="{'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'}">

                    <template v-slot:item.tpuName="{item}">
                        {{item.tpuName}} (ล็อต {{item.lotNo}})
                    </template>

                    <template v-slot:item.quantity="{item}">
                        {{item.quantity}} {{item.unit}}
                    </template>

                    <!-- <template v-slot:item.currentQuantity="{item}">
                        {{item.currentQuantity}} {{item.unit}}
                    </template> -->

                    <template v-slot:group.header="{group, items, isOpen, toggle}">
                        <th colspan="1" class="text-body-1">
                            <v-icon @click="toggle" :ref="group" :is-expanded="isOpen">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            {{ items[0].genericName }}
                        </th>
                        <th colspan="1" class="text-body-1">
                            {{items[0].expired}}
                        </th>
                        <th colspan="1" class="text-body-1">
                                {{sumQuantity(items)}} {{items[0].unit}}
                        </th>
                        <!-- <th colspan="1" class="text-body-1">
                                {{items[0].sumCurrentQuantity}} {{items[0].unit}}
                        </th> -->
                    </template>
                </v-data-table>
            </v-tab-item>
            <!-- Herb -->
            <v-tab-item>
                <v-data-table :items-per-page="10" :items="itemHerbList" :headers="itemNonDrugHeader" group-by="genericName" :footer-props="{'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'}">

                    <template v-slot:item.tpuName="{item}">
                        {{item.tpuName}} - (ล็อต {{item.lotNo}})
                    </template>

                    <template v-slot:item.quantity="{item}">
                        {{item.quantity}} {{item.unit}}
                    </template>

                    <!-- <template v-slot:item.currentQuantity="{item}">
                        {{item.currentQuantity}} {{item.unit}}
                    </template> -->

                    <template v-slot:group.header="{group, items, isOpen, toggle}">
                        <th colspan="1" class="text-body-1">
                            <v-icon @click="toggle" :ref="group" :is-expanded="isOpen">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            {{ items[0].genericName }}
                        </th>
                        <th colspan="1" class="text-body-1">
                            {{items[0].expired}}
                        </th>
                        <th colspan="1" class="text-body-1">
                                {{sumQuantity(items)}} {{items[0].unit}}
                        </th>
                        <!-- <th colspan="1" class="text-body-1">
                                {{items[0].sumCurrentQuantity}} {{items[0].unit}}
                        </th> -->
                    </template>
                </v-data-table>
            </v-tab-item>
            <!-- Other -->
            <v-tab-item>
                <v-data-table :items-per-page="10" :items="itemOtherList" :headers="itemNonDrugHeader" group-by="genericName" :footer-props="{'items-per-page-text' : 'จำนวนรายการที่แสดงต่อหน้า'}">

                    <template v-slot:item.tpuName="{item}">
                        {{item.tpuName}} (ล็อต {{item.lotNo}})
                    </template>

                    <template v-slot:item.quantity="{item}">
                        {{item.quantity}} {{item.unit}}
                    </template>

                    <!-- <template v-slot:item.currentQuantity="{item}">
                        {{item.currentQuantity}} {{item.unit}}
                    </template> -->

                    <template v-slot:group.header="{group, items, isOpen, toggle}">
                        <th colspan="1" class="text-body-1">
                            <v-icon @click="toggle" :ref="group" :is-expanded="isOpen">{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
                            {{ items[0].genericName }}
                        </th>
                        <th colspan="1" class="text-body-1">
                            {{items[0].expired}}
                        </th>
                        <th colspan="1" class="text-body-1">
                                {{sumQuantity(items)}} {{items[0].unit}}
                        </th>
                        <!-- <th colspan="1" class="text-body-1">
                                {{items[0].sumCurrentQuantity}} {{items[0].unit}}
                        </th> -->
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>

        <!-- TABLE -->
            

    </v-container>
</template>
<script>
export default {
    data:()=>{
        return {
            tab : null,
            withdrawDate : null,
            objective: null,
            destination: null,
            itemDrugHeader : [
                {
                    'text':'ชื่อสามัญ',
                    'value':'genericName',
                },{
                    'text':'ชื่อทางการค้า',
                    'value':'tpuName',
                },{
                    'text':'วันหมดอายุ',
                    'value':'expired',
                },{
                    'text':'จำนวนเบิก',
                    'value':'quantity',
                },
                // {
                //     'text':'จำนวนคงคลังหลังเบิก',
                //     'value':'currentQuantity',
                // }
            ],
            itemNonDrugHeader : [
                {
                    'text':'ชื่อสามัญ',
                    'value':'genericName',
                },{
                    'text':'ชื่อทางการค้า',
                    'value':'tpuName',
                },{
                    'text':'วันหมดอายุ',
                    'value':'expired',
                },{
                    'text':'จำนวนเบิก',
                    'value':'quantity',
                },
                // {
                //     'text':'จำนวนคงคลังหลังเบิก',
                //     'value':'currentQuantity',
                // }
            ],
            itemDrugList : [
                {
                    'genericName':'Amoxycilin 500 mg',
                    'tpuName':'Xylomox-500',
                    'dosage':'500 mg',
                    'packingSize':'500',
                    'unit':`*500's กล่อง`,
                    'lotNo':'7458',
                    'quantity':'1',
                    'expired':'14 JAN 2022',
                    'currentQuantity' : '6',
                    'sumCurrentQuantity' : '49'
                }
            ],
            itemNonDrugList : [
                {
                    'genericName':'ถุงยางอนามัย ( Condom) 49 mm',
                    'tpuName':'Durex',
                    'dosage':'500 mg',
                    'packingSize':'500',
                    'unit':`*200 's กล่อง`,
                    'lotNo':'1785',
                    'quantity':'1',
                    'expired':'14 JAN 2022',
                    'currentQuantity' : '9',
                    'sumCurrentQuantity' : '29'
                }
            ],
            itemHerbList : [],
            itemOtherList: [],
        }
    },
    methods:{
        initialize(){
            let list = this.$route.params.list;
            this.withdrawDate = list.date;
            this.objective = list.objective;
            this.destination = list.destination;
            this.itemDrugList = new Array();
            this.itemNonDrugList = new Array();
            this.itemHerbList = new Array();
            this.itemOtherList = new Array();
            console.log(list.list);
            list.list.forEach(item => {
                var data = {
                    'genericName':item.generic_name,
                    'tpuName':item.trade_name,
                    'dosage':item.dosage,
                    'packingSize':item.lot_uom,
                    'unit':item.lot_uom,
                    'lotNo':item.lot_no,
                    'quantity':item.amount,
                    'expired': new Date(item.expiry_date).toLocaleDateString("th-TH"),
                }
                // console.log(item);
                // console.log(data);
                if(item.generic_type == 'med'){
                    this.itemDrugList.push(data);
                }else if(item.generic_type == 'nonmed'){
                    this.itemNonDrugList.push(data);
                }else if(item.generic_type == 'herb'){
                    this.itemHerbList.push(data);
                }else if(item.generic_type == 'other'){
                    this.itemOtherList.push(data);
                }
            });
            console.log(this.itemDrugList);
        },
        applyRouterLink(name){
            this.$router.push({name})
        },  
        sumQuantity(items){
            var sumQuan = 0
            items.forEach(e => {
                sumQuan += parseInt(e.quantity)
            });
            return sumQuan
        },
    },
    mounted() {
        this.initialize()
    },
    computed:{
        sumList() {
            return this.itemDrugList.length+this.itemNonDrugList.length+this.itemHerbList.length+this.itemOtherList.length
        }
    },
}
</script>